import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'theme-ui'
import { Global, css } from '@emotion/react'

import theme from '../theme'

const Layout = ({ children }) => (
  <React.StrictMode>
    <Global
      styles={css`
        html,
        body,
        #___gatsby {
          margin: 0;
          padding: 0;
          overflow-x: hidden;
        }

        * {
          box-sizing: border-box;
        }
      `}
    />
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </React.StrictMode>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
